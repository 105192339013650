import { DEFAULT_THEME } from '@mantine/core';
import { PaperDemos } from '@demos/core';
import { ThemeColorExtendDemo, ThemeFontsExtendDemo, PrimaryColorDemo } from '@docs/demos';
import { ThemeColors } from "../../../../src/components/ThemeColors/ThemeColors";
import * as React from 'react';
export default {
  DEFAULT_THEME,
  PaperDemos,
  ThemeColorExtendDemo,
  ThemeFontsExtendDemo,
  PrimaryColorDemo,
  ThemeColors,
  React
};