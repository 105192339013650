import { useIntervalHook } from '@docs/demos';
import { CenterDemos, TabsDemos, RingProgressDemos, BadgeDemos, NumberInputDemos } from '@demos/core';
import * as React from 'react';
export default {
  useIntervalHook,
  CenterDemos,
  TabsDemos,
  RingProgressDemos,
  BadgeDemos,
  NumberInputDemos,
  React
};