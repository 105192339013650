// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-gallery-gallery-category-page-tsx": () => import("./../../../src/components/Gallery/GalleryCategoryPage.tsx" /* webpackChunkName: "component---src-components-gallery-gallery-category-page-tsx" */),
  "component---src-components-gallery-gallery-component-page-tsx": () => import("./../../../src/components/Gallery/GalleryComponentPage.tsx" /* webpackChunkName: "component---src-components-gallery-gallery-component-page-tsx" */),
  "component---src-components-gallery-gallery-tsx": () => import("./../../../src/components/Gallery/Gallery.tsx" /* webpackChunkName: "component---src-components-gallery-gallery-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-getting-started-tsx": () => import("./../../../src/pages/getting-started.tsx" /* webpackChunkName: "component---src-pages-getting-started-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mdx-slug-tsx": () => import("./../../../src/pages/{Mdx.slug}.tsx" /* webpackChunkName: "component---src-pages-mdx-slug-tsx" */)
}

