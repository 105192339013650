import { CalendarDemos, RangeCalendarDemos, MonthDemos, DatePickerDemos, TimeInputDemos, DateRangePickerDemos } from '@demos/dates';
import { AccordionDemos, SelectDemos, AutocompleteDemos, JsonInputDemos, SliderDemos, AffixDemos } from '@demos/core';
import * as React from 'react';
export default {
  CalendarDemos,
  RangeCalendarDemos,
  MonthDemos,
  DatePickerDemos,
  TimeInputDemos,
  DateRangePickerDemos,
  AccordionDemos,
  SelectDemos,
  AutocompleteDemos,
  JsonInputDemos,
  SliderDemos,
  AffixDemos,
  React
};