import { TimelineDemos, DialogDemos, ListDemos, ChipsDemos, PaginationDemos } from '@demos/core';
import { TimeRangeInputDemos } from '@demos/dates';
import * as RichTextDemos from '@demos/rte';
import * as React from 'react';
export default {
  TimelineDemos,
  DialogDemos,
  ListDemos,
  ChipsDemos,
  PaginationDemos,
  TimeRangeInputDemos,
  RichTextDemos,
  React
};