import { TextDemos, AlertDemos, BlockquoteDemos, ButtonDemos, BadgeDemos, SimpleGridDemos, GridDemos, CollapseDemos } from '@demos/core';
import * as React from 'react';
export default {
  TextDemos,
  AlertDemos,
  BlockquoteDemos,
  ButtonDemos,
  BadgeDemos,
  SimpleGridDemos,
  GridDemos,
  CollapseDemos,
  React
};