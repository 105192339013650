import { CalendarDemos, RangeCalendarDemos, MonthDemos, DatePickerDemos, TimeInputDemos, DateRangePickerDemos } from '@demos/dates';
import * as React from 'react';
export default {
  CalendarDemos,
  RangeCalendarDemos,
  MonthDemos,
  DatePickerDemos,
  TimeInputDemos,
  DateRangePickerDemos,
  React
};