import { ButtonDemos, SliderDemos, TabsDemos, AccordionDemos } from '@demos/core';
import { CalendarDemos } from '@demos/dates';
import * as CORE_STYLES_API from '@mantine/core/src/styles.api';
import * as React from 'react';
export default {
  ButtonDemos,
  SliderDemos,
  TabsDemos,
  AccordionDemos,
  CalendarDemos,
  CORE_STYLES_API,
  React
};