import { UseDebouncedValueBaseDemo } from '@docs/demos';
import { SegmentedControlDemos, PopoverDemos, DividerDemos, GroupDemos, GridDemos } from '@demos/core';
import * as React from 'react';
export default {
  UseDebouncedValueBaseDemo,
  SegmentedControlDemos,
  PopoverDemos,
  DividerDemos,
  GroupDemos,
  GridDemos,
  React
};